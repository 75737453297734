const onlyNumbers = (event) => {
  const regex = new RegExp('^[0-9 -+]+$')
  const key = String.fromCharCode(!event.charCode ? event.which : event.charCode)
  if (!regex.test(key)) {
    event.preventDefault()
    return false
  }
}

export default onlyNumbers
