<template>
  <v-form v-model="isValid" ref="form" lazy-validation>
    <v-row>
      <v-col cols="12" sm="6" md="4" lg="3">
        <CustomInput
          v-model="formData.firstName"
          id="firstName"
          name="firstName"
          :label="$t('label.firstName')"
          :rules="firstNameRules"
          :disabled="isDisabled"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <CustomInput
          v-model="formData.lastName"
          id="lastName"
          name="lastName"
          :label="$t('label.lastName')"
          :rules="lastNameRules"
          :disabled="isDisabled"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <CustomInput
          v-model="formData.city"
          id="city"
          name="city"
          :label="$t('label.city')"
          :rules="cityRules"
          :disabled="isDisabled"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <CustomInput
          v-model="formData.email"
          id="email"
          name="email"
          autocomplete="email"
          :label="$t('label.email')"
          :rules="emailRules"
          readonly
          disabled
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <CustomInput
          v-model="formData.phone"
          id="phone"
          name="phone"
          autocomplete="phone"
          :label="$t('label.phone')"
          :rules="phoneRules"
          :disabled="isDisabled"
          @keypress="onlyNumbers"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <CustomInput
          v-model="formData.company"
          id="company"
          name="company"
          :label="$t('label.company')"
          :rules="companyRules"
          :disabled="isDisabled"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <CustomInput
          v-model="formData.address"
          id="address"
          name="address"
          :label="$t('label.address')"
          :rules="addressRules"
          :disabled="isDisabled"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <CustomInput
          v-model="formData.country"
          id="country"
          name="country"
          :label="$t('label.country')"
          :rules="countryRules"
          :disabled="isDisabled"
        />
      </v-col>
    </v-row>
    <v-slide-y-transition hide-on-leave>
      <div v-if="isDisabled" key="edit" class="text-center my-5">
        <ActionButton outlined @click="edit">
          {{ $t('button.edit') }}
        </ActionButton>
      </div>
      <div v-else key="action" class="text-center my-5">
        <ActionButton
          color="green"
          :loading="loading"
          :disabled="!isValid"
          outlined
          class="mx-5 mx-sm-10 mx-md-20 mb-8"
          @click="save"
        >
          {{ $t('button.save') }}
        </ActionButton>
        <ActionButton
          :disabled="loading"
          color="red"
          outlined
          class="mx-5 mx-sm-10 mx-md-20 mb-8"
          @click="discard"
        >
          {{ $t('button.discard') }}
        </ActionButton>
      </div>
    </v-slide-y-transition>
  </v-form>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import onlyNumbers from '@/helpers/onlyNumbers'

export default {
  name: 'Profile',
  components: {},
  data: () => ({
    isValid: false,
    isDisabled: true,
    formData: {},
  }),
  computed: {
    ...mapState({
      isCheckLocale: (state) => state.isCheckLocale,
      profile: (state) => state.user.profile,
      loading: (state) => state.user.profileLoading,
    }),
    firstNameRules() {
      return this.isCheckLocale ? [(v) => !!v || this.$t('rules.firstNameRequired')] : []
    },
    lastNameRules() {
      return this.isCheckLocale ? [(v) => !!v || this.$t('rules.lastNameRequired')] : []
    },
    cityRules() {
      return this.isCheckLocale ? [(v) => !!v || this.$t('rules.cityRequired')] : []
    },
    phoneRules() {
      return this.isCheckLocale ? [(v) => !!v || this.$t('rules.phoneRequired')] : []
    },
    companyRules() {
      return this.isCheckLocale ? [(v) => !!v || this.$t('rules.companyRequired')] : []
    },
    addressRules() {
      return this.isCheckLocale ? [(v) => !!v || this.$t('rules.addressRequired')] : []
    },
    countryRules() {
      return this.isCheckLocale ? [(v) => !!v || this.$t('rules.countryRequired')] : []
    },
    emailRules() {
      return this.isCheckLocale
        ? [
            (v) => !!v || this.$t('rules.emailRequired'),
            (v) =>
              /^([a-zA-Z0-9_\-\\./]+)@([a-zA-Z0-9_\-\\./]+)\.([a-zA-Z]{2,10})$/.test(v) ||
              this.$t('rules.emailValid'),
          ]
        : []
    },
  },
  mounted() {
    this.loadProfile()
  },
  methods: {
    ...mapActions({
      getProfile: 'user/GET_PROFILE',
      updateProfile: 'user/UPDATE_PROFILE',
    }),
    onlyNumbers,
    async loadProfile() {
      await this.getProfile()
      this.formData = {...this.profile}
    },
    edit() {
      this.isDisabled = !this.isDisabled
    },
    async save() {
      if (this.$refs.form.validate()) {
        await this.updateProfile(this.formData)
        await this.reset()
      }
    },
    discard() {
      this.reset()
      this.formData = {...this.profile}
    },
    reset() {
      this.$refs.form.resetValidation()
      this.isDisabled = !this.isDisabled
    },
  },
}
</script>
